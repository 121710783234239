export default (data = {}) => {
    return {
        account: null,
        project: null,
        code: null,
        road: null,
        direction: null,
        name: null,
        pr_from: null,
        pr_to: null,
        pr_length: null,
        pr_width: null,
        pr_height: null,
        pr_volume: null,
        pr_volume_uom: null,
        pr_area: null,
        pr_area_uom: null,
        note: null,
        issue_date: null,
        agent: null,
        picture: null,
        ...data
    }
}
