export default (data = {}) => {
    return {
        account: null,
        code: null,
        name: null,
        project: null,
        description: null,
        issue_date: null,
        due_date: null,
        ...data
    }
}




