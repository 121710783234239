<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar elevation="2">
              <v-tabs show-arrows v-model="tab">
                <v-tab key="task"> Task </v-tab>
                <v-tab key="photo">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>
                <v-tab key="map">
                  <v-icon> mdi-map-search-outline </v-icon>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn icon fab color="success" @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="task">
                <v-row class="pa-2">
                  <v-col cols="6" md="6">
                    <v-combobox
                      v-model="item.road"
                      hide-details="auto"
                      label="Ruta Nacional"
                      :items="roads"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-combobox
                      v-model="item.direction"
                      hide-details="auto"
                      label="Sentido"
                      :items="directions"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.name"
                      hide-details="auto"
                      label="Nombre"
                      outlined
                      required
                      dense
                    />
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.pr_from"
                      hide-details="auto"
                      label="PR inicial"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.pr_to"
                      hide-details="auto"
                      label="PR final"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      hide-details="auto"
                      :rules="f_required"
                      v-model="item.pr_length"
                      label="Largo"
                      outlined
                      required
                      dense
                      suffix="M"
                      @keyup="messureChange"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      hide-details="auto"
                      :rules="f_required"
                      v-model="item.pr_width"
                      label="Ancho"
                      outlined
                      required
                      dense
                      suffix="M"
                      @keyup="messureChange"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.pr_height"
                      label="Espesor aprox."
                      hide-details="auto"
                      outlined
                      required
                      dense
                      suffix="M"
                      @keyup="messureChange"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.pr_volume"
                      label="Volumen"
                      hide-details="auto"
                      suffix="M3"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.pr_area"
                      label="Area"
                      class="centered-input"
                      hide-details="auto"
                      outlined
                      required
                      dense
                      suffix="M2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.note"
                      outlined
                      name="input-7-4"
                      label="Observación"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-img
                  contain
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="productPhoto"
                  height="500"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseImage"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <input
                  class="file-input"
                  ref="fileInput"
                  type="file"
                  @input="onSelectFile"
                />
              </v-tab-item>
              <v-tab-item key="map">
                <div class="map">
                  <TaskMap
                    @store-location="updateLocation"
                    :storeLocation="item.location"
                    :recenter="recenter"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="delete_itm(item)" v-if="!newItem">
              <span>Borrar</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" type="submit">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- </v-dialog> -->
    </v-dialog>
    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn
            large
            text
            color="red"
            type="button"
            @click="dialog_confirm = !dialog_confirm"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alerts" hide-overlay persistent>
      <v-card>
        <v-alert border="left" color="orange" prominent text type="warning">
          <v-row align="center">
            <v-col class="grow"> Formato de archivo o tamaño no valido. </v-col>
            <v-col class="shrink">
              <v-btn fab x-small @click="alerts = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/create_task.js";
import TaskMap from "./Location.vue";

export default {
  name: "TaskForm",
  components: { TaskMap },
  data() {
    return {
      alerts: false,
      recenter: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_label: null,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
      chosenPhoto: null,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    roads: {
      type: Array,
      required: false,
    },
    directions: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/webp");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    updateLocation(e) {
      this.item.location = JSON.stringify(e);
    },
    messureChange() {
      this.item.pr_area = this.item.pr_width * this.item.pr_length;
      this.item.pr_volume =
        this.item.pr_width * this.item.pr_length * this.item.pr_height;
    },
    getImagePhoto: function (account, src) {
      var url =
        "https://" + this.$store.getters.getHost + "/" + account + "/road_tasks/" + src;
      console.log(url);
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseLabel() {
      this.$refs.labelInput.click();
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }

        console.log(qry);
        webserver("put_table", qry, () => {
          this.productPhoto = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.item.account,
          code: this.item.code,
        },
      };
      webserver("delete_record", qry, () => {
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
  },
  watch: {
    dialog: function () {
      // if (!e.picture) {
      //   this.compressed_image = null;
      // }
      ////console.log(e);
    },
    item: function (e) {
      this.compressed_image = null;
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, e.picture);
      } else {
        this.productPhoto = null;
      }
    },
  },
};
</script>

<style>
.btn-upload {
  top: 100px;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.map {
  height: 100%;
}
</style>
