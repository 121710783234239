<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>Tasks</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn fab dark small color="info" class="mr-3" to="ClientsMap">
        <v-icon>mdi-map-marker-radius</v-icon>
      </v-btn> -->
      <v-btn fab dark small color="success" @click="newForm">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card elevation="0" height="100%" class="card-outter">
      <v-card-title>
        <small>
          {{ this.project.name }}
        </small>
      </v-card-title>
      <v-card-subtitle>
        <small> {{ this.project.issue_date }} / {{ this.project.due_date }} </small>
      </v-card-subtitle>
      <v-card-text v-if="!$vuetify.breakpoint.xsOnly">
        <small>
          {{ this.project.project }}
        </small>
      </v-card-text>
      <v-card-text v-if="!$vuetify.breakpoint.xsOnly">
        <small>
          {{ this.project.description }}
        </small>
      </v-card-text>
    </v-card>
    <v-sheet elevation="0">
      <v-data-table
        :headers="headers[$vuetify.breakpoint.xsOnly]"
        :items="items"
        no-data-text="No hay tareas registradas"
        :items-per-page="15"
        item-key="code"
        mobile-breakpoint="0"
        :search="search"
        sort-by="category,product_name"
        class="table-cursor mt-3"
      >
        <template v-slot:top> </template>
        <template v-slot:item.name="{ item }">
          {{ item.name }}<br />

          <small v-if="$vuetify.breakpoint.xsOnly">
            {{ item.road + " " + item.direction }}
            <br />
            {{ item.pr_length + " x " + item.pr_width + " x " + item.pr_height }}
          </small>
        </template>
        <template v-slot:item.pr_area="{ item }">
          <span v-if="$vuetify.breakpoint.xsOnly">
            {{ item.pr_area + " M2 / " + item.pr_volume + " M3" }} <br />
            <v-icon small class="mr-2" @click="edit_task(item)"> mdi-pencil </v-icon>
            <v-icon small @click="handleClick(item)"> mdi-magnify </v-icon>
          </span>
          <span v-else>
            {{ item.pr_area }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="edit_task(item)"> mdi-pencil </v-icon>
          <v-icon small @click="handleClick(item)"> mdi-magnify </v-icon>
        </template>
        <template slot="body.append">
          <tr>
            <th>Total</th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
            <th class="text-right" v-if="!$vuetify.breakpoint.xsOnly">
              {{ sumField(items, "pr_volume").toLocaleString(2) }}
            </th>
            <th class="text-right" v-else>
              {{
                sumField(items, "pr_area").toLocaleString(2) +
                " M2  / " +
                sumField(items, "pr_volume").toLocaleString(2) +
                " M3"
              }}
            </th>
            <th class="text-right" v-if="!$vuetify.breakpoint.xsOnly">
              {{ sumField(items, "pr_area").toLocaleString(2) }}
            </th>
            <th v-if="!$vuetify.breakpoint.xsOnly"></th>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>
    <TaskForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createProject from "../../utils/create_project.js";
import createItem from "../../utils/create_task.js";
import TaskForm from "../../components/TaskForm.vue";

export default {
  components: { TaskForm },
  beforeMount() {
    var strn = this.$route.params.project;
    if (strn) {
      this.project = strn;
    } else {
      this.$router.push({ name: "Projects" });
    }
  },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      table: "road_tasks",
      chosenPhoto: null,
      project: createProject(),
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "Ruta Nacional",
            align: "start",
            sortable: true,
            value: "road",
            dataType: "text",
          },
          {
            text: "Sentido",
            align: "start",
            sortable: true,
            value: "direction",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "PR inicial",
            align: "start",
            sortable: true,
            value: "pr_from",
            dataType: "text",
          },
          {
            text: "PR final",
            align: "start",
            sortable: true,
            value: "pr_to",
            dataType: "text",
          },
          {
            text: "Largo (m)",
            align: "end",
            sortable: true,
            value: "pr_length",
            dataType: "text",
          },
          {
            text: "Ancho (m)",
            align: "end",
            sortable: true,
            value: "pr_width",
            dataType: "text",
          },
          {
            text: "Espesor aprox.(m)",
            align: "end",
            sortable: true,
            value: "pr_height",
            dataType: "text",
          },
          {
            text: "Volumen (M3)",
            align: "end",
            sortable: true,
            value: "pr_volume",
            dataType: "text",
          },
          {
            text: "Area (m2)",
            align: "end",
            sortable: true,
            value: "pr_area",
            dataType: "text",
          },
          {
            text: "Observación",
            align: "start",
            sortable: true,
            value: "note",
            dataType: "text",
          },
          {
            text: "",
            width: 100,
            value: "actions",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "",
            align: "end",
            sortable: true,
            value: "pr_area",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    edit_task(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    handleClick(data) {
      //this.item = { ...data };
      // this.newItem = false;
      // this.dialog = true;

      this.$router.push({
        name: "Events",
        params: { project: this.project, task: data },
      });
    },
    view_events(e) {
      console.log(e);
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.project.account },
          { field: "project", operator: "=", value: this.project.code },
        ],
      };
      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        this.items = data;
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, code, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/clients/" +
        src +
        "?" +
        currentdate;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.project.account;
      this.item.project = this.project.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
